import React, {useState, useEffect} from 'react';
import Header from '../../elements/Header/Header';
import axios from 'axios';

import marketPlaceHomeImg from '../../static_data/assets/Home.jpg'

export default function Home(props: any) {
    
    const [tech_prods, setTechProds] = useState([])
    const [clubs, setClubs] = useState([])
    const [proms, setProms] = useState([])

    useEffect(() => {
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.get(`https://axt7388.uta.cloud/market_place/HomePage.php`)
            .then((result: any) => {
                setTechProds(result?.data?.tech_prods);
                setClubs(result?.data?.clubs);
                setProms(result?.data?.proms)
            });
    }, []);
    return (
        <div>
            <Header />
            <div id="root">
                <div className="row">
                    <img src={marketPlaceHomeImg} width="100%" />
                        <form action="/action_page.php" style={{margin: '20px'}}>
                            <input type="text" placeholder="Search in Market Place" name="search" className="search-input" style={{padding: '10px', marginRight: '0px'}} />
                            <button type="submit" style={{padding: '10px', borderRadius: '5px'}}><i className="fa fa-search"></i></button>
                        </form>
                </div>
                <h2>Tech Products</h2>
                <div className="row">
                    {
                        tech_prods.map((prod) => (
                            <div className="col-2" style={{width: '30%'}}>
                                <img src={prod['PROD_PIC']} style={{maxWidth: '40%'}} />
                                <h4>{prod['PROD_NAME']}</h4>
                                <div className="rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-o"></i>
                                </div>
                                <p>$ <b>{prod['PROD_COST']}</b></p>
                            </div>
                        ))
                    }
                    <a className="explore-more-button" href='/login'>{'Explore More >>'}</a>
                    
                </div>
                <h2>Activities</h2>
                <div className="row">
                    {
                        clubs.map((club) => (
                            <div className="col-2" style={{width: '30%'}}>
                                <h4>{club['CLUB_NAME']}</h4>
                                <img src={club['CLUB_PIC']} style={{maxWidth: '40%'}} />
                                <p><b>{club['CLUB_DESC']}</b></p>
                            </div>
                        ))
                    }
                    <a className="explore-more-button" href='/login'>{'Explore More >>'}</a>
                </div>
                <br />
                <h2>Advertisements</h2>
                <div className="row">
                    {
                        proms.map((prom) => (
                            <div className="col-2" style={{width: '30%'}}>
                                <h4>{prom['PROM_NAME']}</h4>
                                <img src={prom['PROM_URL']} style={{maxWidth: '40%'}} />
                                <p><b>{prom['PROM_DESC']}</b></p>
                            </div>
                        ))
                    }
                    <a className="explore-more-button" href='/login'>{'Explore More >>'}</a>
                </div>
            </div>
        </div>
    );
}