import React from 'react';
import Header from '../../elements/Header/Header';

import './Services.css'

import servicesImg from '../../static_data/assets/services.jpg'

export default function Home(props: any) {
    return (
        <div>
            <Header />
            <div className="row">
                <div style={{ width: '90%' }}>
                    <img src={servicesImg} className="services-hero-img" />
                </div>
                <div className="row">
                    <h2 className="section-heading">Our Services</h2>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-bullhorn"></i>
                            </div>
                            <h3>Language Center</h3>
                            <p>
                                Our language center is the perfect place to learn a new language or improve your language
                                skills. We offer a variety of language courses taught by experienced and certified
                                instructors. We also offer a variety of services to help you learn a new language, such as
                                language tutoring, language exchange, and language immersion
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-feed"></i>
                            </div>
                            <h3>Anytime Assistance</h3>
                            <p>
                                Need help? Our team is available 24/7 to assist you with any questions or concerns you may
                                have. Contact us anytime, and we'll be happy to help!
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-shopping-cart"></i>
                            </div>
                            <h3>Shopping</h3>
                            <p>
                                Whether you're looking for clothes, electronics, or accessories, you're sure to find what
                                you're looking for. With a variety of stores and a wide selection of products, there's
                                something for everyone.
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-street-view"></i>
                            </div>
                            <h3>Easy Learning</h3>
                            <p>
                                Learning should be easy and fun! That's why we offer a variety of services to make learning
                                easy and fun for everyone. From online courses to personal tutoring, we have something for
                                everyone. And, we're always here to help if you need us. So, what are you waiting for? Start
                                learning today!
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-broom"></i>
                            </div>
                            <h3>Lightining Events</h3>
                            <p>
                                There are plenty of events and clubs to get involved in at university! Whether you're
                                looking to meet new people, learn new things or just have some fun, there's something for
                                everyone. Check out the services page on your university's website to see what's on and get
                                involved!
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <div className="icon-wrapper">
                                <i className="fas fa-users"></i>
                            </div>
                            <h3>Peer Collaboration</h3>
                            <p>
                                Peer collaboration is an important part of the university experience. It can help students
                                learn from each other and develop new skills. we offers services to support peer
                                collaboration and help students succeed in their studies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}