import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Header.css'

export default function Header(props: any) {
    const userObj = window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '');
    const userType= userObj?.user_type;
    const navigate = useNavigate();

    let changeLocation = (e: any) => {
        console.log('logging ', e.target.value)
        if(e.target.value == '/logout') {
            if(window.confirm('You sure to logout..')) {
                window.localStorage.removeItem('user');
                navigate('/login')
            } else {
                navigate('/')
            }
        } else {
            window.location.href = e.target.value
        }
    }

    return (
        <div className="header">
            <div>
                <a href="/" className="menu_item">Home</a>
            </div>
            <div>
                <a href='http://bxy9716.uta.cloud/uncategorized/home/' className='menu_item'>Blog</a>
            </div>
            <div>
                <a href="/services" className="menu_item">Services</a>
            </div>
            <div>
                <a href="/about-us" className="menu_item">About Us</a>
            </div>
            <div>
                <a href="/contact-us" className="menu_item">Contact Us</a>
            </div>

            <div>
                <select name="member" id="member" onChange={changeLocation}>
                    {userType && <option value="">Signed-in</option>}
                    {!userType && <option value="">Not Signed-in</option>}
                    {!userType && <option value="/login">Login</option>}
                    {!userType && <option value="/register">Register</option>}
                    {userType == 'BSNSS_OWNER' && <option value="/bo_home">Business Owner Home</option>}
                    {userType == 'SUP_ADMIN' && <option value="/sup_adm_home">Super Admin Home</option>}
                    {userType == 'SCHL_ADMIN' && <option value="/sch_adm_home">School Admin Home</option>}
                    {userType == 'STDNT' && <option value="/cust/home">Student Home</option>}
                    {userType == 'STDNT' && <option value="/cust/cart">Student Cart</option>}
                    {userType == 'STDNT' && <option value="/cust/orders">Student Orders</option>}
                    {userType && <option value="/logout">Logout</option>}
                </select>
            </div>

        </div>
    );
}