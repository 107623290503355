import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

// import './Student.css'

import headphonesImg from '../../../static_data/assets/headphones.jpeg'
import macbookImg from '../../../static_data/assets/mackbook.jpeg'
import mobilePhonesImg from '../../../static_data/assets/MobilePhones.jpeg'
import bestTechAccessoriesImg from '../../../static_data/assets/best-tech-accessories.jpeg'
import notebooksImg from '../../../static_data/assets/notebooks.jpeg'
import giftCardImg from '../../../static_data/assets/Gift Crad.jpeg'

import studentProfileImg from '../../../static_data/assets/student_profile.png'
import yogaImg from '../../../static_data/assets/Yoga.jpeg'
import concertImg from '../../../static_data/assets/concert.jpg'
import volunteerImg from '../../../static_data/assets/VOLUNTEER.png'
import AcademicImg from '../../../static_data/assets/Academic.png'
import upcomingEvents from '../../../static_data/assets/upcoming_events.jpg'
import diningImg from '../../../static_data/assets/dining.jpg'
import sportsEvent from '../../../static_data/assets/sports-event.jpg'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)

export default function StudentHome(props: any) {
    const [clubs, setClubs] = useState([])
    const [prods, setProds] = useState([])
    const [nonClubs, setNonClubs] = useState([])
    const [createdClubsByUser, setCreatedClubsbyUser] = useState([])
    const { register: post_register, handleSubmit: post_handleSubmit, formState: { errors: post_errors } } = useForm();
    const [posts, setPosts] = useState([])
    const [enableAddPost, setEnableAddPost] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'STDNT') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])


    useEffect(() => {
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_posts.php`, {
            userName: userObject?.data && userObject?.data[0]?.USR_NAME
        })
            .then((result: any) => {
                setPosts(result?.data?.posts);
            });
    }, []);

    const onPostDelete = (data: any) => {
        if (window.confirm('Please confirm to delete the post')) {
            axios.post(`https://axt7388.uta.cloud/market_place/Delete_post.php`, {
                post_id: data.target.name
            })
                .then((result: any) => {
                    if (result.data = 'success') {
                        alert("Post deleted.")
                        window.location.reload()
                    } else {
                        alert("Post deletion failed.")
                    }
                });
        }
    }

    const onPostAdd = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_post_add.php`, { ...data, userName: userObject?.data && userObject?.data[0]?.USR_NAME }).then((result: any) => {
            console.log('result', result['data'])
            if (result.data == 'success') {
                alert('Post Added');
                window.location.reload()
            } else {
                alert('Post adding Failed');
            }
        });
    }

    useEffect(() => {
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_clubs.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
        })
            .then((result: any) => {
                console.log('club data', result.data)
                setClubs(result.data.clubs)
                setNonClubs(result.data.non_clubs)
            });
    }, []);

    const leaveClub = (data: any) => {
        // const p_id = data.target.value
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_leave_club.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
            'Club_id': data.target.name,
        }).then((result: any) => {
            if (result.data == 'success') {
                console.log('result', result)
                alert('Left Club')
                window.location.reload();
            } else {
                console.log('result is', result)
                alert('Leaving Club Failed')
            }

        });
    }

    const joinClub = (data: any) => {
        // const p_id = data.target.value
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_join_club.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
            'Club_id': data.target.name,
        }).then((result: any) => {
            if (result.data == 'success') {
                console.log('result', result)
                alert('Joined Club')
                window.location.reload();
            } else {
                console.log('result is', result)
                alert('Joining Club Failed')
            }

        });
    }

    // useEffect(() => {
    //     axios.post(`https://axt7388.uta.cloud/market_place/studentCreatedClubs.php`, {
    //         'User_name': userObject?.data && userObject?.data[0]?.User_name,
    //     })
    //         .then((result: any) => {
    //             console.log('result', result)
    //             setCreatedClubsbyUser(result?.data || [])
    //         });
    // }, [])

    const onDeleteClub = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/studentDeleteClub.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
            'Club_id': data.target.name,
        })
            .then((result: any) => {
                alert('Deleted Club')
                window.location.reload();
            });
    }

    useEffect(() => {
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_products.php`, {
            'userName': userObject?.data && userObject?.data[0]?.USR_NAME,
        })
            .then((result: any) => {
                if (result.data.status == 1) {
                    setProds(result?.data?.prods)
                }
            });
    }, []);

    const addToCart = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_add_to_cart.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
            'Prod_id': data.target.name,
        })
            .then((result: any) => {
                if(result.data.status == 1) {
                    alert('Added to cart')
                } else {
                    alert('Failed adding to cart')
                }
            });
    }


    return (
        <div>
            <Header />
            <div id="root">
                <div className="container" style={{ paddingRight: '0px' }}>
                    <div className="content">
                        <div className="left-side">
                            <img className="prof-pic" src={studentProfileImg} width="30%" />
                            <h2>{userObject?.data[0].FIRST_NAME} {userObject?.data[0].LAST_NAME}</h2>
                            <p>{userObject?.data[0].EM_ID}</p>
                            <p>{userObject?.data[0].Major}</p>
                        </div>
                        <div className="right-side">
                            <div className="bordered-div">
                                <h4><u>Products</u></h4>
                                <div className='row'>
                                    {
                                        prods.map((prod) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <img src={prod['PROD_PIC']} style={{ maxWidth: '40%' }} />
                                                <h4>{prod['PROD_NAME']}</h4>
                                                <p>$ <b>{prod['PROD_COST']}</b></p>
                                                {/* <button className="more-button" name={bo_prod['ID']}>Edit</button> */}
                                                <button className="more-button" name={prod['ID']} onClick={addToCart}>Add to Cart</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <button className="explore-more-button">Explore More</button> */}
                            </div>

                            <div className="bordered-div">
                                <h3 style={{ textAlign: "left" }}>Join Clubs</h3>
                                <div className="row">
                                    {nonClubs?.length > 0 && nonClubs.map((club) => (
                                        <div className="col-2">
                                            <img src={club["CLUB_PIC"]} />
                                            <h4>{club["CLUB_NAME"]}</h4>
                                            <button name={club['ID']} onClick={joinClub}>Join Club</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="bordered-div">
                                <h3 style={{ textAlign: "left" }}>Leave Clubs</h3>
                                <div className="row">
                                    {clubs?.length > 0 && clubs.map((club) => (
                                        <div className="col-2">
                                            <img src={club["CLUB_PIC"]} />
                                            <h4>{club["CLUB_NAME"]}</h4>
                                            <button name={club['ID']} onClick={leaveClub}>Leave Club</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='bordered-div'>
                                <h2>Posts</h2>
                                <div className='row'>
                                    {
                                        posts.map((post) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <h4>{post['PST_NAME']}</h4>
                                                <img src={post['PST_PIC']} style={{ maxWidth: '40%' }} />
                                                <p><b>{post['PST_DESC']}</b></p>
                                                <button className="more-button" name={post['ID']}>Edit</button>
                                                <button className="more-button" name={post['ID']} onClick={onPostDelete}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    enableAddPost &&
                                    (<Form onSubmit={post_handleSubmit(onPostAdd)}>
                                        <Form.Field className='contact-field'>
                                            <div className='input-box'>
                                                <b><label className='contact-label'>Post Name</label></b>
                                                <input
                                                    placeholder='Post Name'
                                                    type="text"
                                                    {...post_register("post_name", { required: true, minLength: 10 })}
                                                />
                                            </div>
                                        </Form.Field>
                                        {post_errors.post_name && <p className='error-message'>Please check Post Name</p>}
                                        <Form.Field className='contact-field'>
                                            <div className='input-box'>
                                                <b><label className='contact-label'>Post Image</label></b>
                                                <input
                                                    placeholder='Image'
                                                    type="text"
                                                    {...post_register("post_image", { required: true, minLength: 10 })}
                                                />
                                            </div>
                                        </Form.Field>
                                        {post_errors.post_image && <p className='error-message'>Please check the Image URL</p>}
                                        <Form.Field className='contact-field'>
                                            <div className='input-box'>
                                                <b><label className='contact-label'>Description</label></b>
                                                <input
                                                    placeholder='Post Description'
                                                    type="text"
                                                    {...post_register("post_desc", { required: true, minLength: 20 })}
                                                />
                                            </div>
                                        </Form.Field>
                                        {post_errors.post_desc && <p className='error-message'>Please five desc of atleast 20 characters</p>}
                                        <Button type='submit' className='button explore-more-button'>Add</Button>
                                    </Form>)
                                }
                                <button className="explore-more-button" onClick={() => { setEnableAddPost(!enableAddPost) }}>Add post</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}