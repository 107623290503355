
import { BEGIN } from '../constants';

const INITIAL_STATE = {
}

export const ACTION_HANDLERS: any = {
    [BEGIN]: (state: any, action: any) => {
        return { 
            ...state,
            loading: true
        }
      },
}

export default function AppReducer(state: any= INITIAL_STATE, action: any) : any {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
