import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import logo from './logo.svg';
import './App.css';

import { route_maps } from './routes'

function App() {
  return (
    <Router>
      <Routes>
        {route_maps.map((route_obj) => (
          <Route path={route_obj.path} element={<route_obj.content />} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
