import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';

// import './Student.css'

import p1Img from '../../../static_data/assets/p1.jpg'
import p2Img from '../../../static_data/assets/p2.jpg'
import p3Img from '../../../static_data/assets/p3.jpg'
import p4Img from '../../../static_data/assets/p4.jpg'
import p5Img from '../../../static_data/assets/p5.jpg'
import p6Img from '../../../static_data/assets/p6.jpg'
import p7Img from '../../../static_data/assets/p7.jpg'
import p8Img from '../../../static_data/assets/mouse-new.webp'
import p9Img from '../../../static_data/assets/p9.jpg'
import mealCardImg from '../../../static_data/assets/meal-card.jpg'
import tagImg from '../../../static_data/assets/tag.webp'
import textBookImg from '../../../static_data/assets/textbook.webp'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function StudentOrders(props: any) {
    const navigate = useNavigate();
    const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)
    const [activeOrders, setActiveOrders] = useState([])
    const [deliveredOrders, setDeliveredOrders] = useState([])
    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'STDNT') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])

    useEffect(() => {
        console.log('user_name ', userObject?.data && userObject?.data[0]?.USR_NAME)
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_orders.php`, {
            'userName': userObject?.data && userObject?.data[0]?.USR_NAME
        })
            .then((result: any) => {
                console.log('result ', result)
                setActiveOrders(result.data.active_orders);
                setDeliveredOrders(result.data.delivered_products);
            });
    }, []);

    const returnOrder = () => {
        alert('Intiated Return')
    }

    const cancelOrder = () => {
        alert('Cancel Order')
    }

    const addToCart = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_add_to_cart.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
            'Prod_id': data.target.name,
        })
            .then((result: any) => {
                if(result.data.status == 1) {
                    alert('Added to cart')
                } else {
                    alert('Failed adding to cart')
                }
            });
    }

    return (
        <div>
            <Header />
            <div style={{ paddingLeft: '16px' }}>
                <h2>Manage Products/Orders</h2>
                <div className="bordered-div">
                    <h4><u>Active Products</u></h4>
                    <div className='row'>
                        {
                            activeOrders.map((prod) => (
                                <div className="col-2" style={{ width: '30%' }}>
                                    <img src={prod['PROD_PIC']} style={{ maxWidth: '40%' }} />
                                    <h4>{prod['PROD_NAME']}</h4>
                                    <p>$ <b>{prod['PROD_COST']}</b></p>
                                    {/* <button className="more-button" name={bo_prod['ID']}>Edit</button> */}
                                    <button className="more-button" name={prod['ID']} onClick={cancelOrder}>Cancel</button>
                                    <button className="more-button" name={prod['ID']}>track</button>
                                </div>
                            ))
                        }
                    </div>
                    {/* <button className="explore-more-button">Explore More</button> */}
                </div>
                <div className="bordered-div">
                    <h4><u>Delivered Products</u></h4>
                    <div className='row'>
                        {
                            deliveredOrders.map((prod) => (
                                <div className="col-2" style={{ width: '30%' }}>
                                    <img src={prod['PROD_PIC']} style={{ maxWidth: '40%' }} />
                                    <h4>{prod['PROD_NAME']}</h4>
                                    <p>$ <b>{prod['PROD_COST']}</b></p>
                                    {/* <button className="more-button" name={bo_prod['ID']}>Edit</button> */}
                                    <button className="more-button" name={prod['ID']} onClick={returnOrder}>Return</button>
                                    <button className="more-button" name={prod['ID']} onClick={addToCart}>Re Order</button>
                                </div>
                            ))
                        }
                    </div>
                    {/* <button className="explore-more-button">Explore More</button> */}
                </div>
            </div>
        </div>
    );
}