import React from 'react';
import Header from '../../elements/Header/Header';

import './About.css'

import aboutUsHeroImg from '../../static_data/assets/about-us-hero.jpg'

export default function About(props: any) {
    return (
        <div>
            <Header />
            <div id="root">
                <div className="row">
                    <div className="hero-image-div">
                        <img src={aboutUsHeroImg} className="about-us-hero" style={{width: '80%'}} />
                    </div>
                    <div className="row">
                        <h2 className="section-heading">About Us</h2>
                        <p>
                            Welcome to the Market Place! A one-stop shop for all your needs.


                            Our goal is to provide our customers with a variety of latest products. We've come a long way, so we
                            know exactly which direction to go in order to deliver high-quality, yet budget-friendly products.
                            While providing excellent customer service and friendly support.

                        </p>
                        <br />
                        <p>We are always on the lookout for the latest trends in fashion and technology and put our customer's
                            wishes first. That's why we have happy customers all over the world and are happy to be a part of
                            the fashion and retail industry.

                            You've come to the correct location if you're searching for something novel. We work hard to be
                            diligent and creative, providing our clients with what they want and prioritizing their needs above
                            all else. The fact that we ship to you regardless of where you are situated and endeavor to bring the greatest
                            items at the lowest rates speaks volumes about our belief that shopping is a right, not a luxury.

                            We constantly put our customers' needs first, therefore we really hope you appreciate our items as
                            much as we do providing them for you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}