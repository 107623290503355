import Landing from '../src/pages/LandingPage/Landing'
import Services from '../src/pages/Services/Services'
import About from '../src/pages/About/About'
import Contact from '../src/pages/Contact/Contact'
import Login from '../src/pages/Login_Register/Login'
import Register from './pages/Login_Register/Register'
import ForgotPassword from './pages/Login_Register/ForgotPassword'
import BusinessOwner from './pages/User/BusinessOwner/BusinessOwner'
import SchoolAdmin from './pages/User/SchoolAdmin/SchoolAdmin'
import SuperAdmin from './pages/User/SuperAdmin/SuperAdmin'
import StudentHome from './pages/User/Student/StudentHome'
import StudentCart from './pages/User/Student/StudentCart'
import StudentOrders from './pages/User/Student/StudentOrders'

export const route_maps = [ 
    {
        path: '/',
        exact: true,
        content: Landing
    },
    {
        path: '/services',
        exact: true,
        content: Services
    },
    {
        path: '/about-us',
        exact: true,
        content: About
    },
    {
        path: '/contact-us',
        exact: true,
        content: Contact
    },
    {
        path: '/login',
        exact: true,
        content: Login
    },
    {
        path: '/register',
        exact: true,
        content: Register
    },
    {
        path: '/forgot_password',
        exact: true,
        content: ForgotPassword
    },
    {
        path: '/bo_home',
        exact: true,
        content: BusinessOwner
    },
    {
        path: '/sch_adm_home',
        exact: true,
        content: SchoolAdmin
    },
    {
        path: '/sup_adm_home',
        exact: true,
        content: SuperAdmin
    },
    {
        path: '/cust/home',
        exact: true,
        content: StudentHome
    },
    {
        path: '/cust/cart',
        exact: true,
        content: StudentCart
    },
    {
        path: '/cust/orders',
        exact: true,
        content: StudentOrders
    },
]