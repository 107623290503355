import React from 'react';
import Header from '../../elements/Header/Header';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

import './Login_Register.css'

export default function ForgotPassword(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => {
        console.log('data ', data)
    }
    return (
        <div>
            <Header />
            <div id="root">
                <div className="custom_login_row">
                    <div className="colm-form">
                        <div className="form-container">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Field className='login-field'  style={{ padding: '0px' }}>
                                    <div className='input-box field'  style={{ padding: '0px' }}>
                                        {/* <b><label className='register-label'>Email</label></b> */}
                                        <input
                                            placeholder='Email'
                                            type="text"
                                            {...register("email", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.email && <p className='error-message'>Please enter valid Email</p>}
                                <div className="" style={{ textAlign: 'center' }}>
                                    <Button type='submit' className='btn-login' style={{ width: '90%' }} value='Reset Password'>Reset Password</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}