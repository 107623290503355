import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';

// import './SchoolAdmin.css'

import studentProfileImg from '../../../static_data/assets/student_profile.png'
import studentCustomerImg from '../../../static_data/assets/student_customer.png'
import BOImg from '../../../static_data/assets/business-owner.png'
import yogaImg from '../../../static_data/assets/Yoga.jpeg'
import concertImg from '../../../static_data/assets/concert.jpg'
import volunteerImg from '../../../static_data/assets/VOLUNTEER.png'
import AcademicImg from '../../../static_data/assets/Academic.png'
import upcomingEvents from '../../../static_data/assets/upcoming_events.jpg'
import diningImg from '../../../static_data/assets/dining.jpg'
import sportsEvent from '../../../static_data/assets/sports-event.jpg'
import { useNavigate } from 'react-router-dom';
import BOLogo from '../../../static_data/assets/business-owner-logo.png'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export default function SchoolAdmin(props: any) {
    const [clubs, setClubs] = useState([])
    const [posts, setPosts] = useState([])
    const [labels_1, setLabels_1] = useState([])
    const [data_1, setData_1] = useState([])
    const [labels_2, setLabels_2] = useState(['query_count', 'posts_count', 'clubs_count'])
    const [data_2, setData_2] = useState([5, 3, 6])
    const navigate = useNavigate();
    // const [sch_adms, setSchAdms] = useState([])
    const [stdnts, setStdnts] = useState([])
    const [bos, setBos] = useState([])

    const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)

    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'SCHL_ADMIN') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])

    useEffect(() => {
        axios.get(`https://axt7388.uta.cloud/market_place/SUP_User_data.php`)
            .then((result: any) => {
                if (result.status == 200) {
                    // setSchAdms(result?.data?.sch_adms)
                    setBos(result?.data?.bos)
                    setStdnts(result?.data?.stdnts)
                }
            });
    }, []);

    useEffect(() => {
        axios.get(`https://axt7388.uta.cloud/market_place/Sch_reports.php`)
            .then((result: any) => {
                if (result.status == 200) {
                    let labels_1 = result.data.prod_stats.map((dt: any) => {
                        return dt.CTGRY || 'Other'
                    })
                    let data_1 = result.data.prod_stats.map((dt: any) => {
                        return dt.product_count
                    })
                    setLabels_1(labels_1);
                    setData_1(data_1);
                    let labels_2_keys = Object.keys(result.data.activity_reports[0]) || []
                    let data_2_values = Object.values(result.data.activity_reports[0]) || []
                    // console.log('hey ', labels_2, data_2)
                    let labels_2 = labels_2_keys.map((dt: any) => {
                        return dt || 'Other'
                    })
                    let data_2 = data_2_values.map((dt: any) => {
                        return dt
                    })
                    setLabels_2(labels_2);
                    setData_2(data_2);
                }
            });
    }, [])

    useEffect(() => {
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.get(`https://axt7388.uta.cloud/market_place/HomePage.php`)
            .then((result: any) => {
                setClubs(result?.data?.clubs);
                setPosts(result?.data?.posts);
            });
    }, []);


    const onStudentDelete = (data: any) => {
        if (window.confirm('You sure to remove the all the records of this Student from Market Place')) {
            axios.post(`https://axt7388.uta.cloud/market_place/Delete_student.php`, {
                student_id: data.target.name
            })
                .then((result: any) => {
                    if (result.data = 'success') {
                        alert("User Records deleted.")
                        window.location.reload()
                    } else {
                        alert("User Records deletion failed.")
                    }
                });
        }
    }

    const onClubDelete = (data: any) => {
        if (window.confirm('You sure to remove the all the records of this Club from Market Place')) {
            axios.post(`https://axt7388.uta.cloud/market_place/Delete_club.php`, {
                club_id: data.target.name
            })
                .then((result: any) => {
                    if (result.data = 'success') {
                        alert("Club deleted.")
                        window.location.reload()
                    } else {
                        alert("Club deletion failed.")
                    }
                });
        }
    }

    const onPostDelete = (data: any) => {
        if (window.confirm('Please confirm to delete the post')) {
            axios.post(`https://axt7388.uta.cloud/market_place/Delete_post.php`, {
                post_id: data.target.name
            })
                .then((result: any) => {
                    if (result.data = 'success') {
                        alert("Post deleted.")
                        window.location.reload()
                    } else {
                        alert("Post deletion failed.")
                    }
                });
        }
    }

    return (
        <div>
            <Header />
            <div id="root">
                <div className="sch_admin_container" style={{ paddingRight: '0px' }}>
                    <div className="content">
                        <div className="left-side">
                            <img className="prof-pic" src={studentProfileImg} width="30%" />
                            <h2>{userObject?.data[0].FIRST_NAME} {userObject?.data[0].LAST_NAME}</h2>
                            <p>{userObject?.data[0].EM_ID}</p>
                            <p>Business School</p>
                        </div>
                        <div className="right-side">
                            <div className="bordered-div">
                                <h4><u>Manage Students</u></h4>
                                <div className='row'>

                                    {
                                        stdnts.map((stdnt) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <b>{stdnt['FIRST_NAME']} {stdnt['LAST_NAME']}</b>
                                                <br />
                                                <img src={studentCustomerImg} />
                                                <br />
                                                <button className="more-button" name={stdnt['USR_NAME']}>Edit</button>
                                                <button className="more-button" name={stdnt['USR_NAME']} onClick={onStudentDelete}>Delete</button>
                                            </div>
                                        ))
                                    }

                                </div>
                                {/* <input className="search-more-button" value="Search Student ..." /> */}
                            </div>
                            <div className="bordered-div">
                                <h4><u>Manage Business owners</u></h4>
                                <div className='row'>
                                    {
                                        bos.map((bo) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <b>{bo['FIRST_NAME']} {bo['LAST_NAME']}</b>
                                                <br />
                                                <img src={BOLogo} />
                                                <br />
                                                <button className="more-button" name={bo['USR_NAME']}>Edit</button>
                                                <button className="more-button" name={bo['USR_NAME']}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <input className="search-more-button" value="Search Business Owner ..." /> */}
                            </div>
                            <div className="bordered-div">
                                <h4><u>Moderate Clubs</u></h4>
                                <div className="row">
                                    {
                                        clubs.map((club) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <h4>{club['CLUB_NAME']}</h4>
                                                <img src={club['CLUB_PIC']} style={{ maxWidth: '40%' }} />
                                                <p><b>{club['CLUB_DESC']}</b></p>
                                                <button className="more-button" name={club['ID']}>Moderate</button>
                                                <button className="more-button" name={club['ID']} onClick={onClubDelete}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <input className="search-more-button" value="Search More Clubs ..." /> */}
                            </div>
                            
                            <div className="bordered-div">
                                <h4><u>Moderate Posts</u></h4>
                                <div className="row">
                                    {
                                        posts.map((post) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <h4>{post['PST_NAME']}</h4>
                                                <img src={post['PST_PIC']} style={{ maxWidth: '40%' }} />
                                                <p><b>{post['PST_DESC']}</b></p>
                                                <button className="more-button" name={post['ID']}>Moderate</button>
                                                <button className="more-button" name={post['ID']} onClick={onPostDelete}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <input className="search-more-button" value="Search More Clubs ..." /> */}
                            </div>
                        </div>
                    </div>
                    {/* <input className="search-more-button" value="Search More Clubs ..." /> */}
                </div>
                <div>
                    <h2><u>Reports</u></h2>
                    <div className='row'>
                        <Bar data={{
                            labels: labels_1,
                            datasets: [
                                {
                                    label: 'Product Category vs Count',
                                    data: data_1,
                                    backgroundColor: '#505b8d',
                                }
                            ],
                        }
                        } />
                    </div>
                    <br />
                    <br />
                    <div className='row'>
                        <Bar data={{
                            labels: labels_2,
                            datasets: [
                                {
                                    label: 'Activities vs count',
                                    data: data_2,
                                    backgroundColor: '#4c5b53',
                                }
                            ],
                        }
                        } />
                    </div>
                </div>
            </div>
        </div>
    );
}