import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

import boImg from '../../../static_data/assets/bo-image.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function BusinessOwner(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: prom_register, handleSubmit: prom_handleSubmit, formState: { errors: prom_errors } } = useForm();
    const [bo_prods, setBOProds] = useState([])
    const [bo_proms, setBOProms] = useState([])
    const navigate = useNavigate();
    const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)
    console.log('userobject', userObject)
    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'BSNSS_OWNER') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])

    useEffect(() => {
        axios.post(`https://axt7388.uta.cloud/market_place/BO_Products.php`, {
            'userName': userObject?.data && userObject?.data[0]?.USR_NAME,
        })
            .then((result: any) => {
                if (result.status == 200) {
                    setBOProds(result?.data?.bo_prods);
                    setBOProms(result?.data?.bo_proms);
                }
            });
    }, []);

    const deleteProd = (data: any) => {
        if (window.confirm('Confirm to delte the Product')) {
            axios.post(`https://axt7388.uta.cloud/market_place/BO_Delete_Product.php`, {
                'userName': userObject?.data && userObject?.data[0]?.USR_NAME,
                'Prod_id': data.target.name
            })
                .then((result: any) => {
                    if (result.data == 'success') {
                        alert('Product Deleted');
                        window.location.reload();
                    }
                });
        }
    }

    const deleteProm = (data: any) => {
        if (window.confirm('Confirm to delte the Promotion')) {
            axios.post(`https://axt7388.uta.cloud/market_place/BO_Delete_Promotion.php`, {
                'userName': userObject?.data && userObject?.data[0]?.USR_NAME,
                'Prom_id': data.target.name
            })
                .then((result: any) => {
                    if (result.data == 'success') {
                        alert('Promotion Deleted');
                        window.location.reload();
                    }
                });
        }
    }

    const onProductAdd = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/BO_Add_Product.php`, {...data, userName: userObject?.data && userObject?.data[0]?.USR_NAME}).then((result: any) => {
            console.log('result', result['data'])
            if(result.data == 'success') {
                alert('Product Added');
                window.location.reload()
            } else {
                alert('Product adding Failed');
            }
        });
    }

    const onPromotionAdd = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/BO_Add_Promotion.php`, {...data, userName: userObject?.data && userObject?.data[0]?.USR_NAME}).then((result: any) => {
            console.log('result', result['data'])
            if(result.data == 'success') {
                alert('Promtion Added');
                window.location.reload()
            } else {
                alert('Promotion adding Failed');
            }
        });
    }

    return (
        <div>
            <Header />
            <div id="root">
                <div className="bo_container" style={{ paddingRight: '0px' }}>
                    <div className="content">
                        <div className="">
                            <img className="prof-pic" src={boImg} width="40%" />
                            <h2>{userObject?.data[0].FIRST_NAME} {userObject?.data[0].LAST_NAME}</h2>
                            <p>{userObject?.data[0].EM_ID}</p>
                            <p>{userObject?.data[0].BSNSS_NAME}</p>
                        </div>
                        <h2>Products</h2>
                        <div className="row">
                            {
                                bo_prods.map((bo_prod) => (
                                    <div className="col-2" style={{ width: '30%' }}>
                                        <img src={bo_prod['PROD_PIC']} style={{ maxWidth: '40%' }} />
                                        <h4>{bo_prod['PROD_NAME']}</h4>
                                        <p>$ <b>{bo_prod['PROD_COST']}</b></p>
                                        {/* <button className="more-button" name={bo_prod['ID']}>Edit</button> */}
                                        <button className="more-button" name={bo_prod['ID']} onClick={deleteProd}>Delete</button>
                                    </div>
                                ))
                            }
                            {/* <button className="explore-more-button">{'Add More >>'}</button> */}
                        </div>
                        <h2>Add Product</h2>
                        <Form onSubmit={handleSubmit(onProductAdd)}>
                            <Form.Field className='contact-field'  style={{padding: '0px'}}>
                                <div className='input-box' style={{padding: '0px'}}>
                                    <b><label className='contact-label'>Name</label></b>
                                    <input
                                        placeholder='Name'
                                        type="text"
                                        {...register("name", { required: true, minLength: 5 })}
                                    />
                                </div>
                            </Form.Field>
                            {errors.name && <p className='error-message'>Please check Name</p>}
                            <Form.Field className='contact-field'  style={{padding: '0px'}}>
                                <div className='input-box' style={{padding: '0px'}}>
                                    <b><label className='contact-label'>Image</label></b>
                                    <input
                                        placeholder='Image'
                                        type="text"
                                        {...register("image", { required: true, minLength: 20 })}
                                    />
                                </div>
                            </Form.Field>
                            {errors.image && <p className='error-message'>Please check the Image URL</p>}
                            <Form.Field className='contact-field'  style={{padding: '0px'}}>
                                <div className='input-box' style={{padding: '0px'}}>
                                    <b><label className='contact-label'>Price</label></b>
                                    <input
                                        placeholder='Price'
                                        type="number"
                                        {...register("price", { required: true})}
                                    />
                                </div>
                            </Form.Field>
                            <Button type='submit' className='button explore-more-button'>Add</Button>
                        </Form>
                        <br />
                        <br />
                        <h2>Promotions</h2>
                        <div className="row">
                            {
                                bo_proms.map((bo_prom) => (
                                    <div className="col-2" style={{ width: '30%' }}>
                                        <img src={bo_prom['PROM_URL']} style={{ maxWidth: '40%' }} />
                                        <h4>{bo_prom['PROM_NAME']}</h4>
                                        {/* <button className="more-button" name={bo_prom['ID']}>Modify</button> */}
                                        <button className="more-button" name={bo_prom['ID']} onClick={deleteProm}>Delete</button>
                                    </div>
                                ))
                            }
                            {/* <button className="explore-more-button">{'Add More >>'}</button> */}
                        </div>
                        <br />
                        <br />
                        <h2>Add Promotion</h2>
                        <Form onSubmit={prom_handleSubmit(onPromotionAdd)}>
                            <Form.Field className='contact-field'  style={{padding: '0px'}}>
                                <div className='input-box' style={{padding: '0px'}}>
                                    <b><label className='contact-label'>Name</label></b>
                                    <input
                                        placeholder='Name'
                                        type="text"
                                        {...prom_register("prom_name", { required: true, minLength: 5 })}
                                    />
                                </div>
                            </Form.Field>
                            {prom_errors.prom_name && <p className='error-message'>Please check Name</p>}
                            <Form.Field className='contact-field'  style={{padding: '0px'}}>
                                <div className='input-box' style={{padding: '0px'}}>
                                    <b><label className='contact-label'>Image</label></b>
                                    <input
                                        placeholder='Image'
                                        type="text"
                                        {...prom_register("prom_image", { required: true, minLength: 20 })}
                                    />
                                </div>
                            </Form.Field>
                            {prom_errors.prom_image && <p className='error-message'>Please check the Image URL</p>}
                            <Button type='submit' className='button explore-more-button'>Add</Button>
                        </Form>
 
                    </div>
                </div>
            </div>
        </div>
    )
}