import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';
import { useNavigate } from 'react-router-dom';

import './StudentCart.css'

import bestTechAccessoriesImg from '../../../static_data/assets/best-tech-accessories.jpeg'
import axios from 'axios';

export default function StudentCart(props: any) {
    const [cartItems, setCartItems] = useState([])
    const navigate = useNavigate();
    const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)
    const [totalCost, setTotalCost] = useState(0)
    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'STDNT') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])

    useEffect(() => {
        console.log('user_name ', userObject?.data && userObject?.data[0]?.USR_NAME)
        // axios.get(`https://jxp9700.uta.cloud/index.php`)
        axios.post(`https://axt7388.uta.cloud/market_place/STDNT_cart.php`, {
            'User_name': userObject?.data && userObject?.data[0]?.USR_NAME
        })
            .then((result: any) => {
                console.log('result', result)
                const cartItems = result?.data || [];
                let totalCst = 0;
                console.log('cart items', cartItems)
                for(let i in cartItems) {
                    totalCst += cartItems[i]['p_cnt'] * cartItems[i]['PROD_COST']
                }
                setTotalCost(totalCst);
                setCartItems(cartItems);
            });
    }, []);

    const deleteFromCart = (data: any) => {
        if (window.confirm('confirm to delete product from Cart')) {
            axios.post(`https://axt7388.uta.cloud/market_place/STDNT_delete_cart.php`, {
                'User_name': userObject?.data && userObject?.data[0]?.USR_NAME,
                'Prod_id': data.target.name
            })
                .then((result: any) => {
                    if(result.data == 'success') {
                        alert('Product deleted from Cart.');
                        window.location.reload();
                    } else {
                        alert('Product deletion failed.');
                    }
                });
        }

    }

    return (
        <div>
            <Header />
            <div id="root">
                <div style={{ padding: '100px' }}>
                    <h2>Your Cart</h2>
                    <div>
                        <table cellPadding="0" cellSpacing="0" width="100%">
                            {
                                cartItems.map((item) => (
                                    <tr style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                                        <td><img src={item['PROD_PIC']} className="cart-image" /></td>
                                        <td>{item['PROD_NAME']}</td>
                                        {/* <td>{item['PROD_DESC']}</td> */}
                                        <td style={{ textAlign: 'left' }}>Count: <b>{item['p_cnt']}</b></td>
                                        <td style={{ textAlign: 'right' }}>PPU<b>: {item['PROD_COST']}</b></td>
                                        <td><button onClick={deleteFromCart} name={item['ID']}>Delete</button></td>
                                    </tr>
                                ))
                            }
                        </table>
                        <h3 style={{ textAlign: 'right' }}>Subtotal&emsp;&emsp; $ {totalCost}</h3>
                        <div style={{ textAlign: 'right' }}><button className="checkout-button">Proceed to Checkout</button></div>
                    </div>
                </div>
            </div>
        </div>
    );
}