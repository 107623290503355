import React, { useEffect, useState } from 'react';
import Header from '../../../elements/Header/Header';

// import './SuperAdmin.css'

import studentProfileImg from '../../../static_data/assets/student_profile.png'
import schoolAdminLogo from '../../../static_data/assets/school-admin.png'
import studentCustomerImg from '../../../static_data/assets/student_customer.png'
import BOLogo from '../../../static_data/assets/business-owner-logo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function SuperAdmin(props: any) {
    const [labels_1, setLabels_1] = useState([])
    const [data_1, setData_1] = useState([])
    const [labels_2, setLabels_2] = useState([])
    const [data_2, setData_2] = useState([])
    const [sch_adms, setSchAdms] = useState([])
    const [stdnts, setStdnts] = useState([])
    const [bos, setBos] = useState([])

    const userObject = window?.localStorage?.user && JSON.parse(window.localStorage.user)
    const navigate = useNavigate();
    useEffect(() => {
        if (!(window.localStorage.getItem('user')) || window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user') || '')?.user_type != 'SUP_ADMIN') {
            if (window.confirm('You are not authorized to access this page. Please confirm to login with your credentials') == true) {
                window.localStorage.removeItem('user')
                navigate('/login')
            } else {
                navigate('/')
            }
        }
    }, [])

    useEffect(() => {
        axios.get(`https://axt7388.uta.cloud/market_place/SUP_User_data.php`)
            .then((result: any) => {
                if (result.status == 200) {
                    setSchAdms(result?.data?.sch_adms)
                    setBos(result?.data?.bos)
                    setStdnts(result?.data?.stdnts)
                }
            });
    }, []);

    useEffect(() => {
        axios.get(`https://axt7388.uta.cloud/market_place/Sup_reports.php`)
            .then((result: any) => {
                if (result.status == 200) {
                    let labels_1 = result.data.prod_stats.map((dt: any) => {
                        return dt.CTGRY || 'Other'
                    })
                    let data_1 = result.data.prod_stats.map((dt: any) => {
                        return dt.product_count
                    })
                    setLabels_1(labels_1);
                    setData_1(data_1);
                    let labels_2 = result.data.user_stats.map((dt: any) => {
                        return dt.USERTYPE || 'Other'
                    })
                    let data_2 = result.data.user_stats.map((dt: any) => {
                        return dt.user_count
                    })
                    setLabels_2(labels_2);
                    setData_2(data_2);
                }
            });
    }, [])

    const onStudentDelete = (data: any) => {
        if (window.confirm('You sure to remove the all the records of this Student from Market Place')) {
            axios.post(`https://axt7388.uta.cloud/market_place/Delete_student.php`, {
                student_id: data.target.name
            })
                .then((result: any) => {
                    if (result.data = 'success') {
                        alert("User Records deleted.")
                        window.location.reload()
                    } else {
                        alert("User Records deletion failed.")
                    }
                });
        }
    }

    return (
        <div>
            <Header />
            <div id="root">
                <div className="container" style={{ paddingRight: '0px' }}>
                    <div className="content">
                        <div className="left-side">
                            <img className="prof-pic" src={studentProfileImg} width="30%" />
                            <h2>{userObject?.data[0].FIRST_NAME} {userObject?.data[0].LAST_NAME}</h2>
                            <p>{userObject?.data[0].EM_ID}</p>
                            <p>Admin Area: School</p>
                        </div>
                        <div className="right-side">
                            <div className="bordered-div">
                                <h4><u>Manage School Admins</u></h4>
                                <div className='row'>
                                    {
                                        sch_adms.map((sch_adm) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <b>{sch_adm['FIRST_NAME']} {sch_adm['LAST_NAME']}</b>
                                                <br />
                                                <img src={schoolAdminLogo} />
                                                <br />
                                                <button className="more-button" name={sch_adm['USR_NAME']}>Edit</button>
                                                <button className="more-button" name={sch_adm['USR_NAME']}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <input className="search-more-button" value="Search School Admin ..." /> */}
                            </div>

                            <div className="bordered-div">
                                <h4><u>Manage Students</u></h4>
                                <div className='row'>

                                    {
                                        stdnts.map((stdnt) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <b>{stdnt['FIRST_NAME']} {stdnt['LAST_NAME']}</b>
                                                <br />
                                                <img src={studentCustomerImg} />
                                                <br />
                                                <button className="more-button" name={stdnt['USR_NAME']}>Edit</button>
                                                <button className="more-button" name={stdnt['USR_NAME']} onClick={onStudentDelete}>Delete</button>
                                            </div>
                                        ))
                                    }

                                </div>
                                {/* <input className="search-more-button" value="Search Student ..." /> */}
                            </div>
                            <div className="bordered-div">
                                <h4><u>Manage Business owners</u></h4>
                                <div className='row'>
                                    {
                                        bos.map((bo) => (
                                            <div className="col-2" style={{ width: '30%' }}>
                                                <b>{bo['FIRST_NAME']} {bo['LAST_NAME']}</b>
                                                <br />
                                                <img src={BOLogo} />
                                                <br />
                                                <button className="more-button" name={bo['USR_NAME']}>Edit</button>
                                                <button className="more-button" name={bo['USR_NAME']}>Delete</button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <input className="search-more-button" value="Search Business Owner ..." /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2><u>Reports</u></h2>
                    <div className='row'>
                        <Bar data={{
                            labels: labels_1,
                            datasets: [
                                {
                                    label: 'Product Category vs Count',
                                    data: data_1,
                                    backgroundColor: '#505b8d',
                                }
                            ],
                        }
                        } />
                    </div>
                    <br />
                    <br />
                    <div className='row'>
                        <Bar data={{
                            labels: labels_2,
                            datasets: [
                                {
                                    label: 'User type vs count',
                                    data: data_2,
                                    backgroundColor: '#4c5b53',
                                }
                            ],
                        }
                        } />
                    </div>
                </div>
            </div>
        </div>
    )
}