import React, { useState } from 'react';
import Header from '../../elements/Header/Header';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './Login_Register.css'

export default function Login(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data: any) => {
        console.log(data);
        console.log('errors', errors)
        axios.post(`https://axt7388.uta.cloud/market_place/Login.php`, data).then((result: any) => {
            console.log('result', result['data'])
            if (result['data']['user_type'] === 'STDNT') {
                window.localStorage.setItem("user", JSON.stringify(result['data']));
                navigate('/cust/home');
            } else if (result['data']['user_type'] === 'BSNSS_OWNER') {
                window.localStorage.setItem("user", JSON.stringify(result['data']));
                navigate('/bo_home');
            } else if (result['data']['user_type'] === 'SUP_ADMIN') {
                window.localStorage.setItem("user", JSON.stringify(result['data']));
                navigate('/sup_adm_home');
            } else if (result['data']['user_type'] === 'SCHL_ADMIN') {
                window.localStorage.setItem("user", JSON.stringify(result['data']));
                navigate('/sch_adm_home');
            } else {
                alert('Login Failed, try with valid credentials.')
            }
        }).catch((error) => {
            alert('Login Failed, try with valid credentials.')
        });
    }
    return (
        <div>
            <Header />
            <div id="custom_login_row">
                <div className="row">
                    <div className="colm-form">
                        <div className="form-container" style={{ paddingTop: '0px' }}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>User name</label></b> */}
                                        <input
                                            placeholder='User name'
                                            type="text"
                                            {...register("userName", { required: true, minLength: 6 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.userName && <p className='error-message'>Please check the User Name</p>}
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>Password</label></b> */}
                                        <input
                                            placeholder='Password'
                                            type="text"
                                            {...register("password", { required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.password && <p className='error-message'>Please enter the valid Password</p>}
                                <div className="" style={{ textAlign: 'center' }}>
                                    <Button type='submit' className='btn-login' style={{ width: '50%' }} value='Login'>Login</Button>
                                </div>
                            </Form>
                            <a href="/forgot_password">Forgotten password?</a>
                            <a href="/register"><button className="btn-new">Create new Account</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
