import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import Header from '../../elements/Header/Header';

import './Contact.css'

import contactUsImg from '../../static_data/assets/contact-us-hero.jpg'
import axios from 'axios';

export default function Contact(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/Contact.php`, data)
        .then((result: any) => {
            if(result?.data == 'success') {
                alert('Query Submitted.')
            } else {
                alert('Query submission failed.')
            }
        })
    }

    return (
        <div>
            <Header />
            <div id="root">
                <div className="hero-image-div">
                    <img src={contactUsImg} className="contact-us-hero" />
                </div>
                <div className="container" style={{paddingRight: '0px'}}>
                    <div className="content">
                        <div className="left-side">
                            <div className="address details">
                                <i className="fas fa-map-marker-alt"></i>
                                <div className="topic">Address</div>
                                <div className="text-one">Arlington</div>
                                <div className="text-two">Texas</div>
                            </div>
                            <div className="phone details">
                                <i className="fas fa-phone-alt"></i>
                                <div className="topic">Phone</div>
                                <div className="text-one">+1 9999999999</div>
                                <div className="text-two">+1 9999999999</div>
                            </div>
                            <div className="email details">
                                <i className="fas fa-envelope"></i>
                                <div className="topic">Email</div>
                                <div className="text-one">contact@market-place.com</div>
                                <div className="text-two">info@market-place.com</div>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="topic-text">Send us a message</div>
                            <p>If you have any queries, just reach us with your question. We periodically respond to all the queries. Please be clear in including all questions to get a appropriate response.</p>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Field className='contact-field'>
                                    <div className='input-box'>
                                        <b><label className='contact-label'>Name</label></b>
                                        <input
                                            placeholder='Full Name'
                                            type="text"
                                            {...register("fullName", { required: true, minLength: 10 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.fullName && <p className='error-message'>Please check the your Name</p>}
                                <Form.Field className='contact-field'>
                                    <div className='input-box'>
                                        <b><label className='contact-label'>Email</label></b>
                                        <input
                                            placeholder='Email'
                                            type="text"
                                            {...register("email", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.email && <p className='error-message'>Please check the Email</p>}
                                <Form.Field className='contact-field'>
                                    <div className='input-box'>
                                        <b><label className='contact-label'>Query</label></b>
                                        <input
                                            placeholder='Query'
                                            type="text"
                                            {...register("query", { required: true, minLength: 20 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.query && <p className='error-message'>Query Should be atleast 20 characters</p>}
                                <Button type='submit' className='button'>Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}