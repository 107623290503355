import React from 'react';
import Header from '../../elements/Header/Header';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import './Login_Register.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Register(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data: any) => {
        axios.post(`https://axt7388.uta.cloud/market_place/Register.php`, data).then((result: any) => {
            console.log('result', result['data'])
            if(result.data == 'success') {
                alert('Registration Succeeded');
                navigate('/login');
            } else {
                alert('Registration Failed');
            }
        });
    }
    return (
        <div>
            <Header />
            <div id="root">
                <div className="custom_login_row" style={{ marginTop: '70px' }}>
                    <div className="colm-form">
                        <div className="form-container">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>First name</label></b> */}
                                        <input
                                            placeholder='First name'
                                            type="text"
                                            {...register("firstName", { required: true, minLength: 6 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.firstName && <p className='error-message'>Please check the First Name</p>}
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>Last name</label></b> */}
                                        <input
                                            placeholder='Last name'
                                            type="text"
                                            {...register("lastName", { required: true, minLength: 6 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.lastName && <p className='error-message'>Please check the Last Name</p>}
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>Password</label></b> */}
                                        <input
                                            placeholder='Password'
                                            type="text"
                                            {...register("password", { required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.password && <p className='error-message'>Please enter the valid Password</p>}
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='login-label'>Confirm Password</label></b> */}
                                        <input
                                            placeholder='Confirm Password'
                                            type="text"
                                            {...register("confirmPassword", { required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.password && <p className='error-message'>Passwords should match</p>}
                                <Form.Field className='login-field'  style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='register-label'>Phone Number</label></b> */}
                                        <input
                                            placeholder='Ph. no'
                                            type="text"
                                            {...register("Phno", { required: true, maxLength: 10, pattern: /^\d{10}$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.Phno && <p className='error-message'>Please enter valid Ph.no</p>}
                                <Form.Field className='login-field'  style={{ padding: '0px' }}>
                                    <div className='input-box field'  style={{ padding: '0px' }}>
                                        {/* <b><label className='register-label'>Email</label></b> */}
                                        <input
                                            placeholder='Email'
                                            type="text"
                                            {...register("email", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.email && <p className='error-message'>Please enter valid Email</p>}
                                <Form.Field className='login-field' style={{ padding: '0px' }}>
                                    <div className='input-box field' style={{ padding: '0px' }}>
                                        {/* <b><label className='register-label'>Address</label></b> */}
                                        <input
                                            placeholder='Address'
                                            type="text"
                                            {...register("address", { required: true, maxLength: 20 })}
                                        />
                                    </div>
                                </Form.Field>
                                {errors.address && <p className='error-message'>Please check the Address</p>}
                                <div className="" style={{ textAlign: 'center' }}>
                                    <Button type='submit' className='btn-login' style={{ width: '90%' }} value='Register'>Register</Button>
                                </div>
                            </Form>
                            <a href="/forgot_password">Forgotten password?</a>
                            <a href="/login"><button className="btn-new">Login</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}